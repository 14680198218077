import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { PersonalPage } from './components/PersonalPage'
import Container from '@material-ui/core/Container'
import { validateDrives, validateExpenses, validatePersonalData } from './utils/Validators'
import { ThankYouDialog } from './components/ThankYouDialog'
import { defaultDrive } from './utils/Schemes'
import { useDriveMutation, useExpenseMutation } from './utils/Connector'
import { ExpensePage } from './components/Expense/ExpensePage'
import { TermsPage } from './components/TermsPage'
import { ErrorDialog } from './components/ErrorDialog'
import { OverviewStepper } from './components/OverviewStepper'
import { RFLogo } from './components/RFLogo'
import { ReimbursementSelection } from './components/ReimbursementSelection'
import { DrivePage } from './components/Drive/DrivePage'
import { LoadingPage } from './components/LoadingPage'
import { SummaryPage } from './components/SummaryPage'
import { ButtonBar } from './components/ButtonBar'
import styled from 'styled-components'
import { Drive } from './model/Drive'
import { defaultPersonal, PersonalInput } from './model/PersonalInput'
import { Reimbursement } from './model/Reimbursement'
import { Expense } from './model/Expense'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { trackStep } from './utils/MatomoHelper'
import { useBlockUnload } from './utils/UseBlockUnload'
import { useRateQuery } from './utils/useRateQuery'

export const steps = ['Type', 'Regler', 'Udgifter', 'Personligt', 'Oversigt']

export function LandingPage() {
	const [activeStep, setActiveStep] = useState(0)
	const [reimbursement, setReimbursement] = useState<Reimbursement | undefined>()
	const [personal, setPersonal] = useState<PersonalInput>(defaultPersonal)
	const [drives, setDrives] = useState<Drive[]>([defaultDrive(0)])
	const [driveCounter, setDriveCounter] = useState(0)
	const [expenses, setExpenses] = useState<Expense[]>([])
	const [termsAccepted, setTermsAccepted] = useState(false)
	const { trackPageView, trackEvent } = useMatomo()
	const { isError: isErrorRate, refetch: refetchRate } = useRateQuery()
	useBlockUnload(activeStep !== 0)

	const [thankYouDialogOpen, setThankYouDialogOpen] = useState(false)
	const onSuccess = () => {
		trackEvent({ category: 'Submission success', action: reimbursement === 'drive' ? 'Kørsel' : 'Udgift' })
		setThankYouDialogOpen(true)
	}

	const { mutateAsync: mutateExpenseAsync } = useExpenseMutation()
	const { mutateAsync: mutateDriveAsync } = useDriveMutation()

	useEffect(() => trackStep(trackPageView, activeStep, reimbursement), [activeStep])

	const [error, setError] = useState({
		message: '',
		showError: false,
	})

	async function retry() {
		setActiveStep(5)
		setError((state) => ({ ...state, showError: false }))
		await sendRequest()
	}

	async function sendRequest() {
		try {
			let response =
				reimbursement === 'drive'
					? await mutateDriveAsync({ personal, drives })
					: await mutateExpenseAsync({ personal, expenses })

			if (response.status === 200) onSuccess()
			else setError({ showError: true, message: response.statusText })
		} catch (error: any) {
			setError({ showError: true, message: error?.message })
		}
	}

	const handleNext = async () => {
		setActiveStep((activeStep) => activeStep + 1)
		if (activeStep === 4) await sendRequest()
	}

	const handleBack = () => setActiveStep((activeStep) => activeStep - 1)

	function getStep(activeStep: number) {
		switch (activeStep) {
			case 0:
				return <ReimbursementSelection onChange={setReimbursement} selection={reimbursement} />
			case 1:
				return <TermsPage accepted={termsAccepted} type={reimbursement!} onChange={setTermsAccepted} />
			case 2:
				return reimbursement === 'drive' ? (
					<DrivePage
						drives={drives}
						onChange={setDrives}
						driveCounter={driveCounter}
						onDriveCounterChange={setDriveCounter}
					/>
				) : (
					<ExpensePage value={expenses} onChange={setExpenses} />
				)
			case 3:
				return <PersonalPage state={personal} onChange={setPersonal} reimbursement={reimbursement!} />
			case 4:
				return <SummaryPage personal={personal} reimbursement={reimbursement!} drives={drives} expenses={expenses} />
			case 5:
				return <LoadingPage />
		}
	}

	function nextAllowed() {
		switch (activeStep) {
			case 0:
				return !!reimbursement
			case 1:
				return termsAccepted
			case 2:
				return reimbursement === 'drive' ? drivesAreValid() : expensesAreValid()
			case 3:
				return personalIsValid()
			case 4:
				return true
			default:
				return false
		}
	}

	function drivesAreValid() {
		return validateDrives(drives)
	}

	function personalIsValid() {
		return validatePersonalData(personal)
	}

	function expensesAreValid() {
		return validateExpenses(expenses)
	}

	return (
		<>
			<Grid alignItems="center" container>
				<Grid item xs={12}>
					<RFLogo />
					<MainContainer>
						<OverviewStepper activeStep={activeStep} steps={steps} />
						<Container maxWidth="md">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									{getStep(activeStep)}
								</Grid>
							</Grid>
						</Container>
						{activeStep !== 5 && (
							<ButtonBar
								backAllowed={activeStep !== 0}
								nextAllowed={nextAllowed()}
								onNextClicked={handleNext}
								onBackClicked={handleBack}
								readyToSend={activeStep === 4}
							/>
						)}
					</MainContainer>
				</Grid>
			</Grid>
			<ErrorDialog
				state={error.showError || isErrorRate}
				onRetry={() => {
					if (isErrorRate) refetchRate()
					if (error.showError) retry()
				}}
			/>
			<ThankYouDialog open={thankYouDialogOpen} personal={personal} />
		</>
	)
}

const MainContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: center;
`
