import moment from 'moment'
import { Expense } from '../model/Expense'
import { ApiExpense, Attachment, ExpenseRequest, LostAttachment } from '../model/api/ExpenseRequest'
import { Drive } from '../model/Drive'
import { PersonalInput } from '../model/PersonalInput'
import { Details } from '../model/api/Details'
import { ApiDrive, DriveRequest } from '../model/api/DriveRequest'
import { requiresParticipantList } from './Purposes'

const toInt = (i: string | number): number => parseInt(`${i}`)
const toNumber = (i: string | number): number => Number(`${i}`)

function createDetails(personal: PersonalInput): Details {
	return {
		name: personal.name,
		email: personal.mail,
		registrationNumber: `${personal.regNr}`,
		accountNumber: `${personal.accNr}`,
		organisation: toInt(personal.organisation),
		budgetNumber: personal.budgetNumber !== '' ? toInt(personal.budgetNumber) : undefined,
		additionalComment: personal.reason,
	}
}

function getType(t: string): 1 | 2 {
	if (t === 'application/pdf') return 2
	if (t.toLowerCase().includes('image')) return 1
	throw new Error(`Unknown type: ${t}`)
}

function createAttachment(e: Expense): Attachment | LostAttachment {
	if (!e.lost)
		return {
			type: getType(e.file.type),
			data: e.file.base64,
		}

	return {
		type: 3, //LOST EXPENSE
		description: e.lostReceipt.description,
		reason: e.lostReceipt.reason,
	}
}

function createExpense(e: Expense): ApiExpense {
	return {
		date: moment(e.date).format('YYYY-MM-DD'),
		purpose: toInt(e.purpose),
		amount: toInt(toNumber(e.amount) * 100),
		attachment: createAttachment(e),
		participantList: requiresParticipantList(e.purpose) ? e.participants : undefined,
	}
}

function createDrive(d: Drive): ApiDrive {
	return {
		date: moment(d.date).format('YYYY-MM-DD'),
		purpose: toInt(d.purpose),
		fromAddress: d.fromAddress,
		toAddress: d.toAddress,
		viaAddress: d.viaAddress,
		distance: toInt(d.distance),
		estimatedDistance: d.estimatedDistance === '' ? 0 : toInt(d.estimatedDistance),
	}
}

export function createDriveJSON(personal: PersonalInput, drives: Drive[]): DriveRequest {
	return {
		details: createDetails(personal),
		drives: drives.map((d) => createDrive(d)),
	}
}

export function createExpenseJSON(personal: PersonalInput, expenses: Expense[]): ExpenseRequest {
	return {
		details: createDetails(personal),
		expenses: expenses.map((e) => createExpense(e)),
	}
}
